<template>
    <v-container>
      <v-row dense class="my-8">
          <v-col cols="12" v-for="cat in categories" :key="cat.id">
            <router-link :to="'/category/'+cat.id" class="category">
              <v-btn class="mx-2 category_btn">{{cat.name}}</v-btn>
            </router-link>
          </v-col>
          <v-col cols="12">
              <div class="text-right mt-3 mb-3">
                <router-link to="/cart" v-if="cart_items.length">
                  <v-btn text small> 
                    <v-icon small class="mr-3">fas fa-shopping-cart</v-icon>
                    Košarica
                  </v-btn>
                </router-link>
              </div>
          </v-col>
      </v-row>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      cart_items: 'cart/cartProducts',
      categories: 'products/getMainCategories'
    }),
  },
  beforeCreate(){
    if(this.$store.getters['user/getCredentials'].narucivanje == 0) this.$router.push({ name: 'not_auth' })
    this.$store.dispatch('products/getMainCategories', /*this.$route.params.category_id*/)
  }
}
</script>
<style scoped>
  .category, .category_btn{
      width:100%
  }
</style>